import { words } from 'lodash'
import { intervalToDuration, formatDuration, formatISO } from 'date-fns'

export function can(ability, authorizable) {
    if (authorizable.can === undefined) {
        return false
    }

    return authorizable.can[ability]
}

export const diffForHumans = ({ start, end }) => {
    const durations = intervalToDuration({ start, end })

    return formatDuration(durations)
}

export const formatNotificationCreatedAt = (createdAt) => {
    const isOld = intervalToDuration({ start: new Date(), end: new Date(createdAt) }).days >= 7

    if (isOld) {
        return formatISO(new Date(createdAt), { representation: 'date' })
    }

    return (
        words(diffForHumans({ start: new Date(), end: new Date(createdAt) }))
            .slice(0, 2)
            .join(' ') + ' ago'
    )
}
